












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: {
    loading: {
      type: Boolean,
    }
  }
})
export default class Loading extends Vue {

}
