
















import { Component, Watch, Vue } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import dayjs from 'dayjs';

@Component({
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  }
})
export default class DateRangeSelect extends Vue {
  value: Date[];
  selectedItem = 'all_time';
  selectedDates: Date[] = [];

  items = [
    {
      label: 'All Time',
      value: 'all_time',
    },
    {
      label: '1 Day',
      value: 'day',
    },
    {
      label: '1 Week',
      value: 'week',
    },
    {
      label: '1 Month',
      value: 'month',
    },
    {
      label: '1 Year',
      value: 'year',
    },
    {
      label: 'Customized',
      value: '',
      disabled: true,
    },
  ];

  get nowDate() {
    return dayjs().endOf('day').toDate();
  }

  get pickerOptions() {
    return {
      disabledDate: (date: Date) => {
        return +date > +this.nowDate;
      },
    };
  }

  onChangeDates(value: Date[]) {
    if (value && value.length) {
      this.emitInput([
        dayjs(value[0]).startOf('day').toDate(),
        dayjs(value[1]).endOf('day').toDate(),
      ]);
    } else {
      this.selectedItem = 'all_time';
    }
  }

  emitInput(value, selectedItem = '') {
    this.$emit('input', value);
    this.selectedItem = selectedItem;
  }

  @Watch('selectedItem')
  onChangeSelectedTime() {
    let changed = false;
    switch (this.selectedItem) {
      case 'all_time':
        this.selectedDates = [];
        changed = true;
        break;

      case 'day':
      case 'week':
      case 'month':
      case 'year':
        this.selectedDates = [
          dayjs().subtract(1, this.selectedItem).startOf('day').toDate(),
          this.nowDate,
        ];

        changed = true;
        break;
    }

    if (changed) {
      this.emitInput(this.selectedDates, this.selectedItem);
    }
  }
}
