


































import { Component, Prop, Vue } from 'vue-property-decorator';
import CoinTable from './components/CoinTable.vue';

@Component({
  components: {
    CoinTable,
  },
})
export default class CoinTableCard extends Vue {
  search = {
    currency: '',
  };

  decimalFilter = {
    isOpen: false,
    active: false,
    decimal: 2,
  };

  get selectedDecimalText() {
    return (
      this.decimalFilter.decimal +
      (this.decimalFilter.decimal <= 1 ? ' decimal' : ' decimals')
    );
  }
}
