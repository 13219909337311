






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: {
    value: {
      type: String,
      default: '',
    }
  }
})
export default class TxsGroupBySelect extends Vue {
  value: string;
  selectedItem: string = '';

  items = [
    {
      label: 'Hour',
      value: 'hour',
    },
    {
      label: 'Date',
      value: 'date',
    },
    {
      label: 'Month',
      value: 'month',
    },
    {
      label: 'Year',
      value: 'year',
    },
  ];

  mounted() {
    if (this.value) {
      this.selectedItem = this.value;
    }
  }

  emitInput(value) {
    this.$emit('input', value);
  }
}
