












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: {
    text: {
      type: String,
    }
  }
})
export default class CopyAble extends Vue {
  copyText = 'Copy'

  copySuccess() {
    this.copyText = 'Copied';
    window.setTimeout(() => {
      this.copyText = 'Copy';
    }, 1000);
  }
  
  copyError() {
    this.copyText = 'Error'
    window.setTimeout(() => {
      this.copyText = 'Copy';
    }, 1000);
  }
}
