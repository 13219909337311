











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  props: {
    currency: {
      type: String,
      default: '',
    },
  },
})
export default class CoinImg extends Vue {
  currency: string;
  get imgPath() {
    return `https://crypitor-storage.s3-ap-southeast-1.amazonaws.com/tokens/${this.currency.toLowerCase()}.png`;
  }
}
