





















import { Component, Prop, Vue } from 'vue-property-decorator';
import IoChart from './components/IoChart.vue';
import IoChartV2 from './components/IoChartV2.vue';
import TxsReportQuery from '@/components/shared/TxsReportQuery.vue';

@Component({
  components: {
    IoChart,
    IoChartV2,
    TxsReportQuery,
  },
})
export default class IoChartCard extends Vue {
  reportQuery: any = {
    currency: 'ETH',
    group_by: 'hour',
  };
}
