







import { Component, Prop, Vue } from 'vue-property-decorator';
import NumberOfTransaction from './components/NumberOfTransaction.vue';

@Component({
  components: {
    NumberOfTransaction,
  },
})
export default class NumberOfTransactionCard extends Vue {}
