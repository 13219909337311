




































import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
// import TwoFaAuthenticatorCard from './TwoFaAuthenticatorCard.vue';
import AccountCard from './AccountCard.vue';
import CurrentPlanCard from './CurrentPlanCard.vue';
// import LoginPasswordCard from './LoginPasswordCard.vue';
// import ApiKeyCard from './ApiKeyCard.vue';
import IoChartCard from './IoChartCard.vue';
import NumberOfTransactionCard from './NumberOfTransactionCard.vue';
import CoinTableCard from './CoinTableCard.vue';

@Component({
  components: {
    // TwoFaAuthenticatorCard,
    AccountCard,
    CurrentPlanCard,
    // LoginPasswordCard,
    // ApiKeyCard,
    IoChartCard,
    NumberOfTransactionCard,
    CoinTableCard,
  }
})
export default class AccountDetailPage extends Vue {
}
