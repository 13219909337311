





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { reportCoin } from '@/apis/report.api';
import { mounted } from '@/utils/decorators/VueTimmer';
import CoinReportRecord from '@/dtos/coinReportRecord';
import CoinImg from '@/components/shared/CoinImg.vue';
import CopyAble from '@/components/shared/CopyAble.vue';

@Component({
  components: {
    CoinImg,
    CopyAble,
  },
  props: {
    currency: {
      type: String,
      default: '',
    },
    decimal: {
      type: Number,
      default: 8,
    },
  },
})
export default class CoinTable extends Vue {
  currency: string;
  decimal: Number;
  coinReportRecords: CoinReportRecord[] = [];
  sortIndex = 1;
  sortDirection = 'desc';
  hasData = false;
  isLoading = false;

  @mounted
  reportCoin() {
    this.isLoading = true;
    return reportCoin()
      .then(data => {
        this.coinReportRecords = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  changeSort(columnIndex) {
    this.sortIndex = columnIndex;
    this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc';
  }

  get getFilteredData() {
    let filteredData = [];
    if (this.currency == '') filteredData = this.coinReportRecords;
    else
      filteredData = this.coinReportRecords.filter(r =>
        r.symbol.toLowerCase().includes(this.currency.toLowerCase()),
      );
    filteredData = this.sortData(filteredData);
    this.hasData = filteredData.length > 0;
    return filteredData;
  }

  sortData(data) {
    let sortedData = null;
    if (this.sortIndex == 1) sortedData = data.sort(this.sortBySymbol);
    else if (this.sortIndex == 2)
      sortedData = data.sort(this.sortByNumberOfTransaction);
    else if (this.sortIndex == 3) sortedData = data.sort(this.sortByTotalIn);
    else if (this.sortIndex == 4) sortedData = data.sort(this.sortByTotalOut);
    else if (this.sortIndex == 5) sortedData = data.sort(this.sortByTotal);

    if (this.sortDirection == 'desc') sortedData = sortedData.reverse();
    return sortedData;
  }

  sortBySymbol(a, b) {
    if (a.symbol < b.symbol) return -1;
    if (a.symbol > b.symbol) return 1;
    return 0;
  }

  sortByNumberOfTransaction(a, b) {
    if (a.count < b.count) return -1;
    if (a.count > b.count) return 1;
    return 0;
  }

  sortByTotalIn(a, b) {
    if (a.total_in < b.total_in) return -1;
    if (a.total_in > b.total_in) return 1;
    return 0;
  }

  sortByTotalOut(a, b) {
    if (a.total_out < b.total_out) return -1;
    if (a.total_out > b.total_out) return 1;
    return 0;
  }

  sortByTotal(a, b) {
    if (a.total < b.total) return -1;
    if (a.total > b.total) return 1;
    return 0;
  }
}
