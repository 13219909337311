











import { Component, Prop, Vue } from 'vue-property-decorator';
import { reportCoin } from '@/apis/report.api';
import CoinReportRecord from '@/dtos/coinReportRecord';
import { mounted } from '@/utils/decorators/VueTimmer';
import CoinImg from '@/components/shared/CoinImg.vue';
import _ from 'lodash';

@Component({
  components: {
    CoinImg,
  },
  props: {
    value: {
      type: String,
      default: '',
    }
  }
})
export default class CoinSelect extends Vue {
  coinReportRecords: CoinReportRecord[] = [];
  isLoading = false;
  currency: string = '';
  value: string;
  searchText = '';

  get filteredRecords(): CoinReportRecord[] {
    if (!this.searchText) {
      return this.coinReportRecords;
    }

    return this.coinReportRecords.filter(report => {
      return report.symbol.toLowerCase().includes(this.searchText);
    });
  }

  @mounted
  reportCoin() {
    this.isLoading = true;
    return reportCoin()
      .then(data => {
        this.coinReportRecords = _.sortBy(data, ['symbol']);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    this.currency = this.value;
  }

  emitInput(value) {
    this.$emit('input', value);
  }

  filterCoin(searchText, y) {
    this.searchText = searchText.toLowerCase();
  }
}
