

















import { Component, Watch, Vue } from 'vue-property-decorator';
import CoinSelect from './CoinSelect.vue';
import TxsGroupBySelect from './TxsGroupBySelect.vue';
import DateRangeSelect from './DateRangeSelect.vue';
import _ from 'lodash';
import { Radio } from 'element-ui';

@Component({
  components: {
    CoinSelect,
    TxsGroupBySelect,
    DateRangeSelect,
    'ElRadio': Radio,
  },
  props: {
    value: {},
  },
})
export default class TransactionReportQuery extends Vue {
  dates: Date[] = [];
  value: any;
  query = {
    currency: 'ETH',
    group_by: 'hour',
    from: -1,
    to: -1,
  };

  get finalQuery() {
    let dates =
      this.dates.length === 2 ? [+this.dates[0], +this.dates[1]] : [-1, -1];
    return {
      ...this.query,
      from: dates[0],
      to: dates[1],
    };
  }

  mounted() {
    if (this.value) {
      this.syncQuery();
    }
  }

  syncQuery() {
    this.query = _.assign({}, this.finalQuery, this.value);
    if (this.query.from !== -1) {
      this.dates = [new Date(this.query.from), new Date(this.query.to)];
    } else {
      this.dates = [];
    }

    this.emitInput();
  }

  emitInput() {
    this.$nextTick(() => {
      this.$emit('input', {
        ...this.finalQuery,
      });
    });
  }

  @Watch('value')
  onChangeValue(newVal) {
    if (!_.isEqual(newVal, this.finalQuery)) {
      this.syncQuery();
    }
  }
}
