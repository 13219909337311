















import { Component, Watch, Vue, Mixins } from 'vue-property-decorator';
import { mounted } from '@/utils/decorators/VueTimmer';
import { reportTransaction } from '@/apis/report.api';
import TransactionReportRecord from '@/dtos/transactionReportRecord';
import _ from 'lodash';
import { numberWithComma } from '@/services/numberUtils';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import * as ResizeDetector from 'resize-detector';
import Loading from '@/components/shared/Loading.vue';

@Component({
  components: {
    'v-chart': ECharts,
    Loading,
  },
  props: {
    reportQuery: {
      default: () => {
        return {
          currency: 'ETH',
          group_by: 'hour',
        };
      },
    },
  },
})
export default class IoChartV2 extends Vue {
  transactionReportRecords: TransactionReportRecord[] = [];
  reportQuery: any;
  isReady = false;
  bindedResize: any = null;

  @Watch('reportQuery', { deep: true })
  @mounted
  reportTransaction() {
    this.isReady = false;
    return reportTransaction(this.reportQuery).then(data => {
      this.transactionReportRecords = data;
      setTimeout(() => {
        this.isReady = true;
        this.$nextTick(() => {
          this.resizeChart();
        });
      }, 1000);
    });
  }

  get inReport() {
    return _.map(this.transactionReportRecords, 'i');
  }

  get outReport() {
    return _.map(this.transactionReportRecords, 'o');
  }

  get kReport() {
    return _.map(this.transactionReportRecords, 'k');
  }

  get cReport() {
    return _.map(this.transactionReportRecords, 'c');
  }

  get tReport() {
    return this.transactionReportRecords.map((transaction, i) => {
      let t = transaction.t;
      return {
        value: Math.abs(t),
        itemStyle: {
          color: t >= 0 ? '#7fbe9e' : '#FD6A6A'
        }    
      };
    });
  }

  get tReportDown() {
    return this.transactionReportRecords.map((transaction, i) => {
      let t = transaction.t;
      if (t < 0) {
        return -t;
      } else {
        return 0;
      }
    });
  }

  get chartOptions() {
    let options = {
      axisPointer: {
        link: { xAxisIndex: 'all' },
      },
      toolbox: {
        left: 'center',
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          saveAsImage: {},
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      grid: [
        {
          top: 40,
          left: '6%',
          right: '2%',
          height: 200,
          bottom: 250,
        },
        {
          left: '6%',
          right: '2%',
          height: 125,
          bottom: 190,
        },
        {
          left: '6%',
          right: '2%',
          height: 120,
          bottom: 30,
        },
      ],
      legend: {
        data: this.kReport,
        position: 'top',
      },
      xAxis: [
        {
          type: 'category',
          scale: true,
          data: this.kReport,
          axisLabel: { show: false },
        },
        {
          type: 'category',
          scale: true,
          gridIndex: 1,
          data: this.kReport,
          axisLabel: { show: false },
        },
        {
          type: 'category',
          gridIndex: 2,
          data: this.kReport,
          scale: true,
        },
      ],
      yAxis: [
        {
          scale: true,
          name: `Amount of ${this.reportQuery.currency}`,
          namePosition: 'start',
          nameTextStyle: {
            align: 'left'
          }
        },
        {
          scale: true,
          gridIndex: 1,
          name: `Total amount of ${this.reportQuery.currency}`,
        },
        {
          scale: true,
          gridIndex: 2,
          name: 'Number of transactions',
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 1, 2],
          start: 10,
          end: 100,
          minValueSpan: 15,
          zoomOnMouseWheel: 'ctrl',
          moveOnMouseMove: 'ctrl',
          zoomLock: false,
        },
        {
          type: 'inside',
          xAxisIndex: [0, 1, 2],
          start: 10,
          end: 100,
          minValueSpan: 15,
          zoomOnMouseWheel: 'ctrl',
          moveOnMouseMove: 'ctrl',
          zoomLock: false,
        },
        {
          type: 'inside',
          xAxisIndex: [0, 1, 2],
          start: 10,
          end: 100,
          minValueSpan: 15,
          zoomOnMouseWheel: 'ctrl',
          moveOnMouseMove: 'ctrl',
          zoomLock: false,
        },
      ],
      series: [
        {
          name: 'In amount',
          type: 'line',
          data: this.inReport,
          smooth: true,
          symbol: 'none',
          tooltip: {
            formatter: val => {
              return `${numberWithComma(val)} ${this.reportQuery.currency}`;
            },
          },
          animationEasing: 'backOut',
        },
        {
          name: 'Out amount',
          type: 'line',
          data: this.outReport,
          smooth: true,
          symbol: 'none',
        },
        {
          name: `Total amount of ${this.reportQuery.currency}`,
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.tReport,
          barGap: '-100%',
        },
        {
          name: 'Number of transactions',
          type: 'bar',
          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            color: '#2983FF',
          },
          data: this.cReport,
        },
      ],
      color: ['#2983FF', '#FD6A6A'],
    };

    return options;
  }

  beforeDestroy() {
    ResizeDetector.removeListener(this.$el as HTMLElement, this.bindedResize);
  }

  @mounted
  watchResize() {
    let bindedResize = _.debounce(() => {
      this.resizeChart();
    }, 300);

    ResizeDetector.addListener(this.$el as HTMLElement, bindedResize);
    this.bindedResize = bindedResize;
  }

  resizeChart() {
    if (this.$refs.chart) {
      (this.$refs.chart as ECharts).resize();
    }
  }
}
