


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { PlanDetail, AccountPlanDetail, AccountDetail } from '@/dtos/Account';

@Component
export default class AccountCard extends Vue {
  @Getter('account/detail')
  account!: AccountDetail;

  getDayLeft() {
    let delta = this.account.plan_expire_at - new Date().getTime();
    let days = Number((delta / 86400000).toFixed(0));
    let dayDelta = days * 86400000;
    return (delta <= dayDelta)? days : days + 1;
  }
}
