

















import { Component, Provide, Vue } from 'vue-property-decorator';
import { mounted } from '@/utils/decorators/VueTimmer';
import { PlanDetail, AccountPlanDetail, AccountDetail } from '@/dtos/Account';
import { keyBy, fromPairs, mapValues } from 'lodash';
import { listPlan, accountPlan } from '@/apis/plan.api';

@Component
export default class CurrentPlanCard extends Vue {
  @Provide()
  accountPlan: Record<string, AccountPlanDetail> = {};

  @mounted
  getAccountPlan() {
    accountPlan().then(e => (this.accountPlan = keyBy(e, 'currency')));
  }
}
